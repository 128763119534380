<template>
  <div class="app-main-content">
    <card>
      <template #tools>
        <div class="title">阶段统计报表</div>
        <div class="right">
          <div class="item">
            <span class="label">负责人</span>
            <a-select
                style="width: 250px;height: 32px;overflow: hidden;"
                allowClear
                :maxTagCount="2"
                mode="multiple"
                show-search
                optionFilterProp="children"
                v-model="selectOwners"
                class="sort-select"
                placeholder="请选择"
                @change="ownersChange"
            >
              <a-select-option v-for="item in ownersList" :value="item.oid" :key="item.oid">
                {{ item.title ? item.name + '-' + item.title : item.name }}
              </a-select-option>
            </a-select>
          </div>
          <div class="item">
            <span class="label">状态</span>
            <a-select class="sort-select" placeholder="请选择" v-model="queryParams.phase_status"
                      @change="statusChange">
              <a-select-option :value="item.value" v-for="item in statusList" :key="item.value">{{ item.name }}
              </a-select-option>
            </a-select>
          </div>
          <div class="item">
            <span class="label">搜索</span>
            <a-input class="search-input" v-model="queryParams.project_name" placeholder="请输入项目名称">
              <a-icon slot="prefix" type="search" />
            </a-input>
          </div>
          <div class="item">
            <a-button class="search" @click="onSearch">查询</a-button>
          </div>
          <div class="item">
            <a-button class="export" @click="onExport">导出报表</a-button>
          </div>
        </div>
      </template>
      <div class="main">
        <div class="statistics">
          <div class="item" style="margin-right: 114px;">
            <div class="logo">
              <img :src="`${$appsettings.imgPrefix}state@4.png`" alt="">
            </div>
            <div class="info">
              <div class="title">阶段个数</div>
              <div class="count">{{ phaseCount.phase_total_count }}</div>
            </div>
          </div>
          <div class="item" style="margin-right: 155px;">
            <div class="logo">
              <img :src="`${$appsettings.imgPrefix}notstart@4.png`" alt="">
            </div>
            <div class="info">
              <div class="title">未开始</div>
              <div class="count">{{ phaseCount.phase_nostart_count }}</div>
            </div>
          </div>
          <div class="item" style="margin-right: 185px;">
            <div class="logo">
              <img :src="`${$appsettings.imgPrefix}running@4.png`" alt="">
            </div>
            <div class="info">
              <div class="title">进行中</div>
              <div class="count">{{ phaseCount.phase_running_count }}</div>
            </div>
          </div>
          <div class="item" style="margin-right: 155px;">
            <div class="logo">
              <img :src="`${$appsettings.imgPrefix}finish@4.png`" alt="">
            </div>
            <div class="info">
              <div class="title">已完成</div>
              <div class="count">{{ phaseCount.phase_finish_count }}</div>
            </div>
          </div>
          <div class="line" style="margin-right: 155px;"></div>
          <div class="completion-rate">
            <a-progress type="circle" :percent="Number(phaseCount.phase_finish_rate)" strokeColor="#0889ff">
              <template #format>
                <div style="font-size: 24px;font-weight: 700;color: #262626">{{ phaseCount.phase_finish_rate }}%</div>
                <div style="font-size: 16px;font-weight: 400;color: #262626">完成率</div>
              </template>
            </a-progress>
          </div>
        </div>
        <div class="main-table">
          <div class="title">数据详情</div>
          <a-table v-if="stageData.length>0" :data-source="stageData" rowKey="oid" childrenColumnName="items"
                   defaultExpandAllRows
                   :expandIcon="expandIcon" :pagination="false">
            <a-table-column title="项目名称" data-index="phase_name"></a-table-column>
            <a-table-column title="负责人" width="250px">
              <template #default="row">
                <div class="person-in-charge">
                  <img class="avatar" :src="row.phase_admin_pic" alt="" />
                  <span>{{
                      row.phase_admin_title ? row.phase_admin_name + '-' + row.phase_admin_title : row.phase_admin_name
                    }}</span>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="计划开始" width="200px">
              <template #default="row">
                <span v-if="row.schedule_end_date">{{ dayjs(row.schedule_start_date).format('YYYY-MM-DD') }}</span>
              </template>
            </a-table-column>
            <a-table-column title="计划结束" width="200px">
              <template #default="row">
                <span v-if="row.schedule_end_date">{{ dayjs(row.schedule_end_date).format('YYYY-MM-DD') }}</span>
              </template>
            </a-table-column>
            <a-table-column title="状态" width="200px">
              <template #default="row">
                <span v-if="row?.phase_status_name!==undefined" class="edp-status" :class="{
                blue:row.phase_status_name==='未开始',
                yellow:row.phase_status_name==='进行中',
                green:row.phase_status_name==='已完成',
              }" style="font-size: 14px">{{ row.phase_status_name }}</span>
              </template>
            </a-table-column>
            <a-table-column title="完成度" width="200px">
              <template #default="row">
                {{ row.complete_percent }}<span v-if="row?.complete_percent!==undefined">%</span>
              </template>
            </a-table-column>
            <a-table-column title="准时率" width="200px">
              <template #default="row">
                {{ row.ontime_rate }}<span v-if="row?.ontime_rate!==undefined">%</span>
              </template>
            </a-table-column>

          </a-table>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { fetch } from "@/utils/request";

export default {
  data() {
    return {
      selectOwners: [],
      queryParams: {
        phase_owners: '',
        phase_status: '',
        project_name: ''
      },
      ownersList: [],
      statusList: [
        { name: '全部', value: '' },
        { name: '未开始', value: '0' },
        { name: '进行中', value: '20' },
        { name: '已完成', value: '90' },
      ],
      stageData: [],
      phaseCount: {}
    }
  },
  created() {
    this.getOwnersList()
    this.getStageData()
  },
  methods: {
    dayjs,
    // 获取负责人数据
    async getOwnersList() {
      const res = await fetch("post", "/cpyaccount/retrieve", { page_size: 9999 })
      if (res.status === 0) {
        this.ownersList = res.data.datas
      }
    },
    // 获取表格数据
    async getStageData() {
      this.stageData = []
      const res = await fetch('post', '/sta/phase', this.queryParams)
      if (res.status === 0) {
        const {
          phase_finish_rate,
          phase_finish_count,
          phase_nostart_count,
          phase_running_count,
          phase_total_count,
          datas
        } = res.data
        this.phaseCount = {
          phase_finish_rate,
          phase_finish_count,
          phase_nostart_count,
          phase_running_count,
          phase_total_count
        }
        this.stageData = datas.map(item => {
          const {
            project_name, project_admin_name, oid, items, pic, ontime_rate, complete_percent,
            project_admin_title
          } = item
          return {
            "phase_name": project_name,
            "phase_admin_name": project_admin_name,
            "phase_admin_pic": pic,
            "phase_admin_title": project_admin_title,
            oid,
            items,
            ontime_rate,
            complete_percent
          }
        })
      }
    },
    // 选择负责人
    ownersChange(e) {
      this.queryParams.phase_owners = e.join(',')
      this.getStageData()
    },
    // 选择状态
    statusChange() {
      this.getStageData()
    },
    // 查询数据
    onSearch() {
      this.getStageData()
    },
    // 导出报表
    async onExport() {
      const { project_name, phase_owners, phase_status } = this.queryParams
      if (this.stageData.length > 0) {
        window.open(this.$toUrl(`/sta/export/phase?project_name=${project_name}&owners=${phase_owners}&status=${phase_status}`, true, "api"))
      } else {
        this.$message.error('暂无数据')
      }
    },
    // 更改树形图标
    expandIcon(props) {
      if (props.record?.items?.length === 0 || props.record?.items === undefined) {
        return <span style="margin-right:19px"></span>
      }
      if (props.expanded) {
        return (
            <a style={{ width: '19px', display: 'inline-block' }} class="table-icon" onClick={(e) => {
              props.onExpand(props.record, e);
            }}>
              <i class="iconfont icon-xiangxia expand-icon"></i>
              {" "}
            </a>
        )
      } else {
        return (
            <a style={{ width: '19px', display: 'inline-block' }} class="table-icon" onClick={(e) => {
              props.onExpand(props.record, e);
            }}>
              <i class="iconfont icon-xiangxia expand-icon open"></i>
              {" "}
            </a>
        )
      }
    }
  },
}
</script>

<style lang="less" scoped>
.app-main-content {
  /deep/ .edp-card {
    .edp-card-head {
      display: none;
    }

    .edp-card-tools {
      padding: 0 30px;

      .title {
        font-weight: 500;
        font-size: 22px;
        color: #4D4D4D;
      }
    }

    .edp-card-body {
      .main {
        padding: 37px 23px 30px 30px;

        .statistics {
          display: flex;
          align-items: center;

          .item {
            display: flex;
            align-items: center;

            .logo {
              img {
                width: 63px;
                height: 62px;
                margin-right: 24px;
              }
            }

            .info {
              .title {
                font-weight: 400;
                font-size: 18px;
                color: #4D4D4D;
              }

              .count {
                font-weight: 700;
                font-size: 34px;
                color: #4D4D4D;
              }
            }
          }

          .line {
            width: 2px;
            height: 71px;
            background: #e5e5e5;
          }

          .completion-rate {
            .ant-progress {
              .ant-progress-inner {
                .ant-progress-circle {
                  .ant-progress-circle-trail {
                    stroke: #e3e9f4 !important;
                  }
                }
              }

            }
          }
        }

        .main-table {
          .title {
            font-weight: 500;
            font-size: 22px;
            color: #4D4D4D;
            margin-top: 26px;
            margin-bottom: 29px;
          }

          .ant-table {

            .ant-table-body {
              .ant-table-thead, .ant-table-tbody {
                tr {
                  th, td {
                    border-right: none;

                    &:last-child {
                      border-right: 1px solid #EDEDED;
                      //text-align: right;
                    }
                  }
                }
              }

              .ant-table-thead {
                span {
                  font-weight: 700;
                  font-size: 18px;
                  color: #4D4D4D;
                }
              }

              .ant-table-tbody {
                td {
                  font-weight: 400;
                  font-size: 16px;
                  color: #4D4D4D;
                }
              }

              .person-in-charge {
                display: flex;
                align-items: center;

                .avatar {
                  width: 20px;
                  height: 20px;
                  line-height: 20px;
                  margin-right: 10px;
                  border-radius: 50%;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
